import { Splash, Promo } from "Pages";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";

const AppRoutes = () => (
  <Router>
    <Routes>
      <Route path="/" element={<Splash />} />
      <Route path="/promo" element={<Promo />} />
      <Route path="/*" element={<Navigate replace to="/" />} />
    </Routes>
  </Router>
);

export default AppRoutes;
