import { useEffect, useState } from 'react'
import './SplashVideo.scss'

const { REACT_APP_SPLASH_VIDEO_ID = 'ADq8CMU3MZ4' } = process.env

const ratio = (400 / 224) * 100

const options = Object.entries({
  controls: 0,
  mute: 1,
  autoplay: 1,
  playlist: REACT_APP_SPLASH_VIDEO_ID,
  list: 'PLNJw96hJgDd5Q24BFgpjcYHqswec0zBW7',
  loop: 1,
})
  .map(([key, value]) => `${key}=${value}`)
  .join('&')

export const SplashVideo = () => {
  const [dimensions, setDimensions] = useState()

  useEffect(() => {
    const onresize = () =>
      setDimensions(
        `${ratio}v${window.innerWidth > window.innerHeight ? 'w' : 'h'}`,
      )
    onresize()
    window.addEventListener('resize', onresize)
    return () => window.removeEventListener('resize', onresize)
  }, [])

  return (
    <div
      className="SplashVideo"
      style={{ width: dimensions, height: dimensions }}
    >
      <div className="filter" />
      <iframe
        style={{ width: dimensions, height: dimensions }}
        title="Splash Video"
        frameBorder="0"
        src={`https://youtube.com/embed/videoseries?${options}`}
      />
    </div>
  )
}
