import { EventsFeed, Panel, ScalingImage, Video } from 'Components'
import { SocialLinks } from 'Components/SocialLinks'
import { Link } from 'react-router-dom'

import './Splash.scss'

const { REACT_APP_VIDEO_ID = '456997828734535' } = process.env

export const Splash = () => (
  <div id="Splash">
    <div>
      <div className="top">
        <ScalingImage ratio={22} url="./logo1200-transparent.png" />
        <span className="tagline">Virginia Beach Rock and/or Roll</span>
      </div>
      <div className="promo">
        Are you a promoter/agent/venue?&nbsp;
        <Link to="promo">Check our promo page!</Link>
      </div>
      <div className="content">
        <Panel className="video">
          <span className="panelHeader">Listen Now!</span>
          <Video id={REACT_APP_VIDEO_ID} />
        </Panel>
        <Panel className="eventsFeedPanel">
          <span className="panelHeader">Upcoming Events</span>
          <EventsFeed />
        </Panel>
      </div>
      <SocialLinks />
    </div>
  </div>
)
