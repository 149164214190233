import axios from 'axios'

const { REACT_APP_API_URL: baseURL = 'https://api.commitmentissuesband.com' } =
  process.env

export const Axios = axios.create({
  baseURL,
})

export { baseURL as API_URL }
