export const EventsFeed = () => (
  <div
    className="fb-page"
    data-href="https://www.facebook.com/CommitmentIssuesBand/"
    data-tabs="timeline"
    data-width=""
    data-height=""
    data-small-header="false"
    data-adapt-container-width="true"
    data-hide-cover="false"
    data-show-facepile="true"
  >
    <blockquote
      cite="https://www.facebook.com/CommitmentIssuesBand/"
      className="fb-xfbml-parse-ignore"
    >
      <a href="https://www.facebook.com/CommitmentIssuesBand/">
        Commitment Issues
      </a>
    </blockquote>
  </div>
)
