import { ScalingImage } from 'Components'
import './Promo.scss'

import { useEffect, useState } from 'react'
import moment from 'moment'
import { GetPlaylist } from 'API'
import { SocialLinks } from 'Components/SocialLinks'

const DEFAULT_STATE = {
  playlist: [],
  error: null,
}

const fetchPlaylist = setState => {
  setState(s => ({ ...s, error: null }))
  GetPlaylist()
    .then(({ data }) => setState(s => ({ ...s, playlist: data })))
    .catch(({ message }) =>
      setState(s => ({
        ...s,
        error: `Unable to load song list: ${message}`,
      })),
    )
}

const ratio = 224 / 400,
  dimensions = 60

  const Roster = ({ list }) =>
  <div className="roster">
    {list.map(([n, ins, bio, pic]) => (
      <div key={n}>
        <div
          className="biopic"
          style={{
            background: `url('./${pic}-480.png')`,
            width: '12vw',
            height: '12vw',
            backgroundSize: 'cover',
          }}
        />
        <div>
          <p>{n}</p>
          <p>{ins}</p>
          <p>{bio}</p>
        </div>
      </div>
    ))}
  </div>

export const Promo = () => {
  const [{ playlist, error }, setState] = useState({ ...DEFAULT_STATE })

  useEffect(() => {
    fetchPlaylist(setState)
  }, [])

  const sortingMethod = ({ artist: a }, { artist: b }) => a.localeCompare(b)

  const setLength = moment.duration(
    playlist.map(({ duration }) => duration).reduce((a, b) => a + b, 0),
  )

  const h = setLength.hours(),
    m = setLength.minutes()

  return (
    <div id="Promo">
      <div>
        <ScalingImage ratio={50} url="./promo.png" />
        <h1>About The Ish</h1>
        <p>
          Bringing you rock! And possibly even roll! Commitment Issues is a
          two-to-five-piece band based out of Virginia Beach, VA. What began as
          a flash-in-the-pan, one night event has now been actively rocking the
          757 since 2020. Playing hours of cover material spanning decades of
          music, "The Ish" features screaming guitars, powerful harmonic vocals,
          and a thumping, primal rhythm section that's sure to thrill. Perfect
          for a party atmosphere, with careful attention to sound levels to
          ensure a rocking, but comfortable experience that drives sales and
          never hinders them.
        </p>
        <p>
          The core group of the band is made up of Brandon Terry on vocals,
          Barry Hicks and Brandon Michael Lee on vocals and guitar, Jordan Cady on bass,
          and Kane Adams on percussion. This configuration can be modified to
          suit duo (acoustic), trio, or four-piece shows in addition to the full
          experience. Lights and sound equipment available on request.
        </p>
        <iframe
          style={{
            width: `${dimensions}vw`,
            height: `${dimensions * ratio}vw`,
            background: 'black',
          }}
          title="Splash Video"
          frameBorder="0"
          src={`https://youtube.com/embed/rRfVXEZrD2k`}
        />
        <h3>Contact us on Social Media!</h3>
        <SocialLinks />
        <h1>Song List</h1>
        {playlist.length ? (
          <>
            <ul>
              {playlist.sort(sortingMethod).map(({ artist, name }) => (
                <li key={name}>
                  {artist} - {name}
                </li>
              ))}
            </ul>
            <span>{`Approximately ${h} hours, ${m} minutes`}</span>
          </>
        ) : (
          <span>{!!error ? error : 'Loading...'}</span>
        )}
        <div className="buttonPanel">
          <button disabled={!error} onClick={() => fetchPlaylist(setState)}>
            Retry
          </button>
        </div>
        <h1>Core Roster</h1>
        <Roster list={[
            ['Brandon Terry (BLT)', 'Vocals', '', 'blt'],
            ['Brandon Michael Lee', 'Vocals, Guitar', '', 'mlee'],
            ['Barry Hicks', 'Vocals, Guitar', '', 'barry'],
            [`Jordan Cady`, 'Bass', '', 'jordan'],
            ['Kane Adams', 'Drums', '', 'kane'],]} />
        <br />
        <h1>Auxiliary Roster</h1>
        <Roster list={[
            [`Aaron "Jesus" Russell`, 'Bass', '', 'jesus'],
            ['Baylee Blume', 'Drums', '', 'baylee']]} />
        <br />
      </div>
    </div>
  )
}
