import "./ScalingImage.scss";

export const ScalingImage = ({ ratio, url, className, ...props }) => (
  <div
    className={`ScalingImage${className ? ` ${className}` : ""}`}
    style={{
      width: `${ratio}vw`,
      height: `${ratio * (628 / 1200)}vw`,
      backgroundImage: `url( '${url}' )`,
    }}
    {...props}
  />
);
