export const Video = ({ id, className = '', ...props }) => (
  <div
    className={`fb-video ${className}`}
    data-href={`https://www.facebook.com/CommitmentIssuesBand/videos/${id}`}
    data-width={Math.min(window.innerWidth - 64, 560)}
    data-allowfullscreen="true"
    data-autoplay="true"
    {...props}
  />
)
