import { SplashVideo } from 'Components'
import Routes from 'Routes'
import './App.scss'

export const App = () => (
  <>
    <Routes />
    <SplashVideo />
  </>
)
