import {
  Facebook,
  Instagram,
  Share,
  SoundCloud,
  TikTok,
  Twitter,
  YouTube,
} from 'Icons'

import './SocialLinks.scss'

const {
  REACT_APP_FACEBOOK_URL = 'https://facebook.com/commitmentissuesband',
  REACT_APP_TWITTER_URL = 'https://twitter.com/theishva',
  REACT_APP_YOUTUBE_URL = 'https://www.youtube.com/channel/UCoj4bbO2SszoppE_KEJlAvg',
  REACT_APP_INSTAGRAM_URL = 'https://www.instagram.com/commitmentissuesband/',
  REACT_APP_SHARE_URL,
  REACT_APP_SOUNDCLOUD_URL,
  REACT_APP_TIKTOK_URL,
} = process.env

export const SocialLinks = () => (
  <div className="SocialLinks">
    {[
      [Facebook, REACT_APP_FACEBOOK_URL],
      [Instagram, REACT_APP_INSTAGRAM_URL],
      [Twitter, REACT_APP_TWITTER_URL],
      [YouTube, REACT_APP_YOUTUBE_URL],
      [SoundCloud, REACT_APP_SOUNDCLOUD_URL],
      [TikTok, REACT_APP_TIKTOK_URL],
      [Share, REACT_APP_SHARE_URL],
    ].map(([Icon, url]) =>
      !url ? null : (
        <a href={url} key={url} target="_blank" rel="noreferrer">
          <Icon />
        </a>
      ),
    )}
  </div>
)
